.navbar {
  background-color:rgba(17, 8, 106, 0.919);
  height: 50px;
  width: 100%;
  color: white;
  display:flex;
  padding-top: 0.5%;
  padding-left: 2%;
  justify-content: left;
  font-weight: bolder;
  font-size: x-large;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.footer {
  width: 100%;
  display:flex;
  /* padding-bottom: 0.1%; */
  margin-bottom: 10px;
  padding-left: 2%;
  justify-content: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.container{
  padding-left: 16px;
  padding-right: 16px;
  /* display: flex; */
  /* justify-content: center; */
}

.content{
  padding: 1%;
  margin-top: 0%;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header4{
  color: black;
  font-weight: bolder;
  font-size: larger;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.button {
  padding: 5px 40px;
  font-size: 21px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color:rgba(197, 50, 50, 0.849);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999; */
}

.button:hover {background-color: rgba(196, 19, 19, 0.849)}

.button:active {
  background-color:rgba(196, 19, 19, 0.849);
  /* transform: translateY(4px); */
}

.button:disabled {
  background-color:rgba(155, 100, 100, 0.849);
}

.slideshow-container {
  position: relative;
  background: #f1f1f1f1;
}

/* Slides */
.mySlides {
  display: none;
  padding: 80px;
  text-align: center;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: large;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  background: #ddd;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active, .dot:hover {
  background-color: #717171;
}

body{
  font-family: 'Playfair Display', serif;
  font-size: large;
  display: grid;

  min-height: 100vh;
}
section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 70vh;
  width: 75vw;
  font-size: large;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px
}
/* .content p{
  padding-bottom: 15px;
  font-weight: 300;
  font-size: large;
  opacity: 0.7;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.7;
  color:#ffffff
} */

